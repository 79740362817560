import * as React from "react";
import Edges from "../components/Edges";
import { Link } from "gatsby";

const NotFoundPage = () => (
    <Edges size="md">
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist.</p>
        <p>
            <Link to="/">Click here to return to the homepage</Link>
        </p>
    </Edges>
);

export default NotFoundPage;
